ul.breadcrumb {
  list-style: none;
  margin-bottom: 0;
  li {
    display: inline;
    a {
      color: unset;
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 0.8px;
        bottom: 0;
        left: 0;
        background-color: #3d3d3d;
        transform-origin: bottom right;
        transition: transform 0.25s;
      }
      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: #3d3d3d;
  content: "/\00a0";
}

.product_details_area {
  margin: 25px 0;
  .product_details_inner {
    .mobile_gallery {
      .ant-carousel {
        .slick-list {
          // position: relative;
          // overflow: hidden;
          img {
            aspect-ratio: 5 / 7;
            object-fit: cover;
          }
        }
      }
    }

    .size-text {
      cursor: pointer;
      // margin-bottom: 7px;
      font-size: 12px;
      position: relative;
      font-weight: 300;
      color: #757575;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(1);
        height: 0.5px;
        bottom: 3px;
        left: 0;
        background-color: #757575;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      // &:hover {
      //   &::after {
      //     transform: scaleX(1);
      //     transform-origin: bottom left;
      //   }
      // }
    }
    .size-text-link {
      cursor: pointer;
      margin-bottom: 7px;
      font-size: 14px;
      position: relative;
      font-weight: 300;
      color: #3d3d3d;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: #3d3d3d;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
      }
      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
    .product_description_area {
      margin-top: 80px;

      .product_img_without_modal {
        .ant-skeleton {
          span {
            width: 100% !important;
          }
        }
        .product-image-carousel {
          width: 50%;
          margin: auto;
          .ant-carousel {
            .slick-list {
              height: 100%;
            }
          }
        }
      }

      .product_img_without_modal img {
        margin: 0 auto;
        display: table;
        width: 100%;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
      }

      .product_description_content h5 {
        font-weight: 300;
        font-style: normal;
        font-size: 16px;
        text-transform: uppercase;
      }
      .product_description_content p {
        line-height: 20px;
        font-weight: 400;
        font-size: 14px;
        span {
          color: #3d3d3d !important;
        }
      }
    }
    .product_section_content {
      .product_gallery_mini {
        position: relative;
        img.item_opacity {
          opacity: 0.5;
        }
        .ant-skeleton-element {
          display: flex;
          width: 75%;
        }
        .next_prev_icons {
          position: absolute;
          bottom: -30px;
          display: flex;
          justify-content: space-around;
          z-index: 999;
          height: 15px;
          .move_icons {
            background-color: #fff;
            padding: 0px 4px;
            cursor: pointer;
            border: 1px solid #8f8e8e;
            display: flex;
            align-items: center;
            height: 16px;
            img, video {
              object-fit: unset !important;
              width: 8px !important;
              height: 8px !important;
              max-width: none !important;
              aspect-ratio: unset !important;
            }
          }
          .move_icons_disabled {
            border: 1px solid #d4d4d4;
            background-color: #fcf9f9;
            img {
              filter: contrast(7);
            }
          }
          #prev_btn {
            img {
              rotate: -90deg;
            }
          }
          #next_btn {
            img {
              rotate: 90deg;
              // margin-bottom: 2px;
            }
          }
        }
      }
      img.item_opacity:hover {
        opacity: 1;
      }
      .product_gallery_mini {
        img, video{
           max-width: 75%;
        // margin-bottom: 15px;
        margin-left: 0px;
        aspect-ratio: 5/7;
        -o-object-fit: cover;
        object-fit: cover;
        cursor: pointer;
        }
       
      }

      .accessories_gallery_min img {
        height: 100%;
      }

      .product_gallery_main  {
        img, video{
           height: auto;
        -o-object-fit: cover;
        object-fit: cover;
        width: 34vw;
        margin-left: -40px;
        }
      }
    }
    .gallery_details_box {
      .product_details {
        p {
          font-size: 14px;
          font-weight: 400;
          text-transform: uppercase;
        }
        .p_name h5,
        .p_price {
          font-size: 14px;
          font-weight: 700;
        }
        .p_top_select {
          .ant-radio-checked .ant-radio-inner {
            border-color: #2c2c2c;
          }
          .ant-radio-wrapper:hover .ant-radio,
          .ant-radio:hover .ant-radio-inner,
          .ant-radio-input:focus + .ant-radio-inner {
            border-color: #2c2c2c;
          }
          .ant-radio-inner::after {
            background-color: #2c2c2c;
          }
        }

        .cmn_ctrl_form {
          font-size: 16px;
          color: #212529;
          border: 0;
          margin: 15px 0;
          border-radius: 0;
        }

        .cmn_ctrl_form:focus {
          outline: none;
          box-shadow: none;
        }

        button.btn_add_bag:focus {
          outline: none;
          box-shadow: none;
        }
        button.btn_add_bag {
          background-color:  #161616;
          color: #fff;
          width: 100%;
          border-radius: 0;
          padding: 0.5rem 0.75rem;
          font-size: 14px;
          font-weight: 700;
          font-style: normal;
          text-transform: uppercase;
        }

        button.btn_add_wishlist {
          border-radius: 50%;
          background: #fff;
          padding: 8px;
          width: 45px;
          height: 40px;
          text-align: center;
          cursor: pointer;
          border: none;
          img {
            width: 18px;
            margin-bottom: 2px;
          }
          &:hover {
            background:  #161616,;
          }
        }

        .guide_return span a {
          margin: 5px 8px 0 0;
          color: #5b5b5b;
          text-decoration: underline;
          -webkit-text-decoration-color: #5b5b5b;
          text-decoration-color: #5b5b5b;
          font-size: 12px;
        }

        .invalid-feedback {
          margin-top: -8px;
          margin-bottom: 8px;
          margin-left: 20px;
        }

        .invalid-feedback::before {
          position: absolute;
          content: "";
          height: 100px;
          width: 100px;
          margin-top: 6px;
          margin-left: -20px;
          margin-bottom: 5px;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        }
        .p_top_select {
          .accordion {
            p {
              color: #3d3d3d;
            }
            .accordion-item {
              // position: absolute;
              width: 100%;
              // z-index: 99;
              .accordion-button:not(.collapsed)::after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMGIwMDAwIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtOTUyLjM2MjE4KSI+PHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC10cmFuc2Zvcm06bm9uZTtkaXJlY3Rpb246bHRyO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2Jhc2VsaW5lLXNoaWZ0OmJhc2VsaW5lO2NvbG9yOiMwMDAwMDA7ZW5hYmxlLWJhY2tncm91bmQ6YWNjdW11bGF0ZTsiIGQ9Im0gNTAuMTg3NDgsMTAyMy4zODM0IGEgMy4wMDAzLDMuMDAwMyAwIDAgMCAxLjgxMjUsLTAuNzUgbCA0MC4wMDAwNCwtMzUuOTk5OTYgYSAzLjAwMDMsMy4wMDAzIDAgMSAwIC00LC00LjQ2ODggbCAtMzguMDAwMDQsMzQuMTg3NDYgLTM4LC0zNC4xODc0NiBhIDMuMDAwMywzLjAwMDMgMCAxIDAgLTMuOTk5OTk5Nyw0LjQ2ODggTCA0Ny45OTk5OCwxMDIyLjYzMzQgYSAzLjAwMDMsMy4wMDAzIDAgMCAwIDIuMTg3NSwwLjc1IHoiIGZpbGw9IiMwYjAwMDAiIGZpbGwtb3BhY2l0eT0iMSIgbWFya2VyPSJub25lIiB2aXNpYmlsaXR5PSJ2aXNpYmxlIiBkaXNwbGF5PSJpbmxpbmUiIG92ZXJmbG93PSJ2aXNpYmxlIj48L3BhdGg+PC9nPjwvc3ZnPg==");
                transform: rotate(180deg);
                background-size: 15px 22px;
                margin-right: -3px;
              }

              .accordion-button::after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMGIwMDAwIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtOTUyLjM2MjE4KSI+PHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC10cmFuc2Zvcm06bm9uZTtkaXJlY3Rpb246bHRyO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2Jhc2VsaW5lLXNoaWZ0OmJhc2VsaW5lO2NvbG9yOiMwMDAwMDA7ZW5hYmxlLWJhY2tncm91bmQ6YWNjdW11bGF0ZTsiIGQ9Im0gNTAuMTg3NDgsMTAyMy4zODM0IGEgMy4wMDAzLDMuMDAwMyAwIDAgMCAxLjgxMjUsLTAuNzUgbCA0MC4wMDAwNCwtMzUuOTk5OTYgYSAzLjAwMDMsMy4wMDAzIDAgMSAwIC00LC00LjQ2ODggbCAtMzguMDAwMDQsMzQuMTg3NDYgLTM4LC0zNC4xODc0NiBhIDMuMDAwMywzLjAwMDMgMCAxIDAgLTMuOTk5OTk5Nyw0LjQ2ODggTCA0Ny45OTk5OCwxMDIyLjYzMzQgYSAzLjAwMDMsMy4wMDAzIDAgMCAwIDIuMTg3NSwwLjc1IHoiIGZpbGw9IiMwYjAwMDAiIGZpbGwtb3BhY2l0eT0iMSIgbWFya2VyPSJub25lIiB2aXNpYmlsaXR5PSJ2aXNpYmxlIiBkaXNwbGF5PSJpbmxpbmUiIG92ZXJmbG93PSJ2aXNpYmxlIj48L3BhdGg+PC9nPjwvc3ZnPg==");
                background-size: 15px 22px;
                margin-right: -3px;
                width: 15px;
                height: 22px;
              }
            }
          }
        }
        .p_bottom_select {
          .accordion p {
            margin: 5px 0;
            color: #3d3d3d;
            // margin-top: 50px !important;
          }
          .accordion {
            .invalid-feedback {
              margin-top: 55px;
            }
            .accordion-item {
              // position: absolute;
              width: 100%;
              // z-index: 9;
              .accordion-button:not(.collapsed)::after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMGIwMDAwIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtOTUyLjM2MjE4KSI+PHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC10cmFuc2Zvcm06bm9uZTtkaXJlY3Rpb246bHRyO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2Jhc2VsaW5lLXNoaWZ0OmJhc2VsaW5lO2NvbG9yOiMwMDAwMDA7ZW5hYmxlLWJhY2tncm91bmQ6YWNjdW11bGF0ZTsiIGQ9Im0gNTAuMTg3NDgsMTAyMy4zODM0IGEgMy4wMDAzLDMuMDAwMyAwIDAgMCAxLjgxMjUsLTAuNzUgbCA0MC4wMDAwNCwtMzUuOTk5OTYgYSAzLjAwMDMsMy4wMDAzIDAgMSAwIC00LC00LjQ2ODggbCAtMzguMDAwMDQsMzQuMTg3NDYgLTM4LC0zNC4xODc0NiBhIDMuMDAwMywzLjAwMDMgMCAxIDAgLTMuOTk5OTk5Nyw0LjQ2ODggTCA0Ny45OTk5OCwxMDIyLjYzMzQgYSAzLjAwMDMsMy4wMDAzIDAgMCAwIDIuMTg3NSwwLjc1IHoiIGZpbGw9IiMwYjAwMDAiIGZpbGwtb3BhY2l0eT0iMSIgbWFya2VyPSJub25lIiB2aXNpYmlsaXR5PSJ2aXNpYmxlIiBkaXNwbGF5PSJpbmxpbmUiIG92ZXJmbG93PSJ2aXNpYmxlIj48L3BhdGg+PC9nPjwvc3ZnPg==");
                transform: rotate(180deg);
                background-size: 15px 22px;
                margin-right: -3px;
              }

              .accordion-button::after {
                background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMGIwMDAwIiB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczpzb2RpcG9kaT0iaHR0cDovL3NvZGlwb2RpLnNvdXJjZWZvcmdlLm5ldC9EVEQvc29kaXBvZGktMC5kdGQiIHhtbG5zOmlua3NjYXBlPSJodHRwOi8vd3d3Lmlua3NjYXBlLm9yZy9uYW1lc3BhY2VzL2lua3NjYXBlIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCwtOTUyLjM2MjE4KSI+PHBhdGggc3R5bGU9InRleHQtaW5kZW50OjA7dGV4dC10cmFuc2Zvcm06bm9uZTtkaXJlY3Rpb246bHRyO2Jsb2NrLXByb2dyZXNzaW9uOnRiO2Jhc2VsaW5lLXNoaWZ0OmJhc2VsaW5lO2NvbG9yOiMwMDAwMDA7ZW5hYmxlLWJhY2tncm91bmQ6YWNjdW11bGF0ZTsiIGQ9Im0gNTAuMTg3NDgsMTAyMy4zODM0IGEgMy4wMDAzLDMuMDAwMyAwIDAgMCAxLjgxMjUsLTAuNzUgbCA0MC4wMDAwNCwtMzUuOTk5OTYgYSAzLjAwMDMsMy4wMDAzIDAgMSAwIC00LC00LjQ2ODggbCAtMzguMDAwMDQsMzQuMTg3NDYgLTM4LC0zNC4xODc0NiBhIDMuMDAwMywzLjAwMDMgMCAxIDAgLTMuOTk5OTk5Nyw0LjQ2ODggTCA0Ny45OTk5OCwxMDIyLjYzMzQgYSAzLjAwMDMsMy4wMDAzIDAgMCAwIDIuMTg3NSwwLjc1IHoiIGZpbGw9IiMwYjAwMDAiIGZpbGwtb3BhY2l0eT0iMSIgbWFya2VyPSJub25lIiB2aXNpYmlsaXR5PSJ2aXNpYmxlIiBkaXNwbGF5PSJpbmxpbmUiIG92ZXJmbG93PSJ2aXNpYmxlIj48L3BhdGg+PC9nPjwvc3ZnPg==");
                background-size: 15px 22px;
                margin-right: -3px;
                width: 15px;
                height: 22px;
              }
            }
          }
        }
        .p_top_select,
        .p_bottom_select {
          .accordion-body {
            padding: 8px 20px;
          }
          .accordion {
            p {
              margin: 5px 0;
            }
          }
          .accordion-button {
            background-color: #ffffff;
            border-bottom: 1px solid #ededed;
            padding: 10px 15px;
            color: #757575;
            text-transform: uppercase;
          }
          .accordion-body {
            background-color: #ffffff;
            color: #212529;
          }
          ul.size_guide {
            // display: flex;
            margin-top: 7px;
            margin-bottom: 7px;
          }
          ul.size_guide li {
            padding: 5px 20px;
            // border: 1px solid #bfbfbf;
            // margin-right: 11px;
            // display: inline-table;

            cursor: pointer;
          }
        }
        .accessories_style {
          .style_title {
            color: #757575;
            font-size: 12px;
          }
          .style_item {
            background-color: #fff;
            // display: inline-block;
            padding: 5px;
            color: #3d3d3d;
            width: 100%;
            border: 1px solid #fff;
            // margin-right: 15px;
            cursor: pointer;
            text-transform: uppercase;
          }
          .active {
            background-color: #ededed;
            border: 1px solid #3d3d3d;
          }
          .not-avail {
            border: 1px solid #8080803d;
            background: #cac2c2a6;
            color: #80808080;
            cursor: not-allowed;
          }
        }
      }
    }
    .product_description_collapse {
      border: none;
      background-color: transparent;
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0px 0;
          font-weight: 500;
          font-size: 14px;
          color: #3d3d3d;
          text-transform: uppercase;
          .ant-collapse-arrow {
            right: 0px;
          }
        }
        border: none;
        background-color: transparent;
        .ant-collapse-content {
          background-color: transparent;
          border: none;
          .ant-collapse-content-box {
            padding: 8px 0;
            p {
              font-size: 14px;
              color: #3d3d3d;
              span {
                color: unset !important;
              }
            }
            span {
              color: unset !important;
            }
          }
        }
      }
    }
    .may_also_like {
      margin: 6rem 0 3rem;
      .title {
        h5 {
          font-weight: 700;
          text-transform: uppercase;
          font-size: 18px;
        }
      }
      .you_may_single {
        img {
          aspect-ratio: 5/7;
          object-fit: cover;
        }
      }
    }
  }
}
@media (min-width: 991px) {
  hr.m_hr {
    display: none;
  }
}
@media (min-width: 770px) {
  .mobile_gallery {
    display: none;
  }
}

@media (max-width: 769px) {
  .product-image-carousel {
    width: 100% !important;
    margin: auto;
  }
  .breadcumb_sec {
    margin-bottom: 15px;
  }
}

@media (min-width: 320px) and (max-width: 567px) {
  .slick-initialized .slick-slide {
    // margin-right: 20px;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .guide_return span button {
    padding-left: 0 !important;
  }
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .guide_return
    span
    a {
    margin: 5px 0px 0 0;
  }
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    button.btn_add_bag {
    font-weight: bold;
  }
  .product_details_area .product_details_inner .product_section_content {
    display: initial;
    justify-content: initial;
  }
  .gallery_box {
    display: none;
  }
  .product_list_bred {
    margin-top: 15px;
  }

  .breadcumb_sec {
    display: block;
  }

  .breadcumb_line {
    // display: none;
  }
  .product_gallery_mini {
    display: none;
  }
  .product_gallery_main img, .product_gallery_main video {
    max-width: 100% !important;
    margin: 0 !important;
  }
  .p_top_select,
  .p_bottom_select {
    .accordion-item {
      position: relative !important;
      width: 100% !important;
    }
  }
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_bottom_select
    .accordion
    .invalid-feedback {
    margin-top: 15px;
  }
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_bottom_select
    .accordion
    p {
    margin: 5px 0;
    margin-top: 5px !important;
  }
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_name
    h5 {
    margin-top: 15px;
  }
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .guide_return {
    display: flex;
    justify-content: space-between;
  }
  hr.m_hr {
    display: block;
  }
  .product_details_area .product_details_inner .product_description_area {
    margin-top: 25px;
  }
  .product_details_area
    .product_details_inner
    .product_description_area
    .product_description_content
    p {
    line-height: 20px;
    // text-align: justify;
    font-size: 14px;
  }
}
.BrainhubCarousel__dots {
  button {
    background-color: transparent !important;
  }
}

.btn_add_wishlist {
  &:hover {
    img {
      filter: brightness(1);
    }
  }
  img {
    filter: brightness(0.2);
  }
}

.size-guide-modal {
  width: 50% !important;
}

@media (max-width: 767px) {
  .size-guide-modal {
    width: 90% !important;
  }
}
