// .product_section {
//   .filter-select {
//     font-size: 16px;
//     margin-bottom: 2px;
//     cursor: pointer;
//     &:hover {
//       text-decoration: underline;
//     }
//   }
// }
#filter-drawer {
  // border: 1px solid #80808052;
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
  .filter-select {
    font-size: 16px;
    margin-bottom: 2px;
    cursor: pointer;
    &:hover {
      text-decoration: underline !important;
    }
  }
  .filter-mobile {
    background: rgb(253, 252, 247);
    padding: 6px 0;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 26px;
    top: 43px;
    box-shadow: 1px 3px 4px 1px #0000002e;
  }
  .filter-section {
    .ant-select-multiple {
      .ant-select-selector {
        width: 100%;
        height: 45px !important;
        border-color: #aba8a85e;
      }
    }
  }
  .close-btn {
    margin-top: 5px;
  }
  .hover-parrent {
    cursor: pointer;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 2px;
      left: 0;
      background-color: #0000008a;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }
  .underline {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 2px;
      left: 0;
      background: #000;
    }
  }
}

.mobile-filter-drop {
  .ant-space-item {
    display: flex;
    font-size: 13px;
  }
}
.ant-dropdown-placement-bottomLeft {
  z-index: 9999;
}
.antd-filter-custom {
  .ant-drawer-header {
    background: #2c2c2c;
    padding: 13px 24px;
  }
}

.btn-load-more {
  width: 300px;
  margin: 30px auto;
}

.empty-skeleton {
  .ant-skeleton-avatar-square {
    // height: unset !important;
    aspect-ratio: 5/7;
  }
}
.grid-icon {
  // margin-right: 10px;
  display: none;
}
.single_product {
  img {
    width: 100%;
    aspect-ratio: 5/7;
    object-fit: cover;
  }
  .normal-card,
  .product-list-carousel {
    .ant-skeleton-avatar-square {
      // height: unset !important;
      aspect-ratio: 5/7;
    }
  }
  // .normal-card,
  .product-list-carousel {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    // aspect-ratio: 5/7;
    z-index: 100;
    display: none;
  }
  .normal-card {
    z-index: 500;
    position: relative;
    .product-tags {
      position: absolute;
      padding: 8px 0 8px 8px;
      flex-direction: column;
      justify-content: space-between;
      z-index: 990;
      .tag-item {
        background: #fff;
        padding: 3px 7px;
        border: 0.5px solid #3d3d3d;
        font-size: 12px;
        font-weight: 300;
        color: #3d3d3d;
      }
    }
  }

  &:hover {
    .product-list-carousel {
      display: block;
    }
    .normal-card {
      // display: none;
    }
  }
}
.single_product > .ant-skeleton-avatar-square,
.empty-skeleton > div > div > .ant-skeleton-avatar-square,
.normal-card > div > div > span {
  width: 100%;
}

.filter-mobile-modal {
  width: -webkit-fill-available;
  max-width: -webkit-fill-available;
  margin: 0;
  transform-origin: 85px 43px;
  margin-top: 40px;
  overflow: hidden;
  height: -webkit-fill-available;
  .ant-modal-footer {
    display: none;
  }
}

.product-list-carousel {
  // .ant-carousel .slick-next {
  //   right: 15px;
  // }
  // .ant-carousel .slick-next {
  //   left: 4px;
  //   z-index: 99;
  // }
  .slick-slide img {
    width: 100%;
  }
  width: 100%;
  .slick-next,
  .slick-prev {
    width: 36px;
    height: 35px;
    background: #fff;
    opacity: 0.6;
    border-radius: 50%;
    &:hover {
      background: #fff;
    }
    z-index: 999;
  }
}
.ant-carousel .slick-prev {
  left: 10px;
  z-index: 9;
}

.ant-carousel .slick-next {
  right: 15px;
}

@media (min-width: 767px) {
  #filter-drawer {
    border: 1px solid #80808052;
  }
}

@media (max-width: 767px) {
  .product_section {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 577px) {
  #filter-drawer {
    .close-btn {
      margin-top: 0;
    }
  }

  .grid-icon {
    display: inline-block;
  }

  .single_product {
    .product-list-carousel {
      display: none;
    }
    &:hover {
      .product-list-carousel {
        display: none;
      }
      .normal-card {
        display: block;
      }
    }
    .normal-card {
      .product-tags {
        .tag-item {
          padding: 1px 6px;
          font-size: 10px;
        }
        span{
          font-size: 10px;
        }
      }
    }
  }

  .btn-load-more {
    width: 300px;
    margin: 7px auto 30px;
  }
}

.single_product {
  .arrow-img {
    width: 6px !important;
  }
}

.mobile_gallery {
  margin-bottom: 30px;
  .slick-dots {
    bottom: -30px;
    li {
      button {
        background-color: #2c2c2c;
        width: 6px;
        height: 6px;
        border-radius: 50%;
      }
    }
    li.slick-active {
      button {
        background-color: #2c2c2c;
      }
    }
  }
}

// .msl-wrp {
//   // .msl-options {
//   //   max-height: 270px;
//   //   overflow-y: scroll;
//   // }
// }

.productlist-loader {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: unset !important;
  max-height: unset !important;
  min-height: 80vh !important;
}
