.title-skeleton {
  ul {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    li {
      height: 25px !important;
    }
  }
}

.breadcrump-skeleton{
  margin-top: -23px !important;
  margin: 0 15px;
  ul {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    li {
      height: 25px !important;
    }
  }
}
