.order_confirmation {
  .order_confirmation_header {
    background-color: #fff;
    padding: 15px 0;
    h5 {
      margin: 0;
    }
  }
  .inner_order_confirmation {
    margin: 40px 0;
    .order_confirmation_wrapper {
      background-color: #fff;
      padding: 15px;
      border-radius: 8px;
      .content {
        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            padding-top: 5px;
          }
        }
      }
    }
  }
}
