.order_detais_area {
  margin-bottom: 80px;
  .inner_order_detais_area {
    .welcome_board_header {
      background-color: #fff;
      padding: 15px 0;
      margin-bottom: 30px;
    }
    .welcome_board_header h5 {
      margin: 0;
    }
    .welcome_board_header p {
      position: absolute;
      margin-top: -23px;
    }

    .orderdetails_inner {
      .bottom_head_order p a {
        text-decoration: underline;
      }
      .orderdetails_container8 {
        background-color: #fff;
        padding: 30px;
        border-radius: 8px;
      }
      .orderdetails_container4,
      .deliveryaddress4 {
        background-color: #fff;
        padding: 30px;
        border-radius: 8px;
      }
    }
    .order_details_product {
      .product_img img {
        width: 100%;
        -o-object-fit: cover;
           object-fit: cover;
      }
      .product_content h5 {
        font-size: 16px;
      }
      .product_content {
        margin-right: 20px;
      }

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin: auto;
      .product_content {
        color: #222222;
      }
      .order_product_box {
        display: flex;
        justify-content: space-between;
      }
    }

    .order_details_product > div {
      box-sizing: border-box;
      margin: 35px 35px 0 0;
      width: calc(1 / 2 * 100% - (1 - 1 / 2) * 35px);
      height: auto;
      color: #fff;
    }

    .order_details_product > div:nth-child(2n) {
      margin-right: 0;
    }

    .order_details_product::after {
      content: "";
      flex: auto;
    }

    .order_details_product > div:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
}
