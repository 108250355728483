.checkout_area {
  padding: 30px 0;

  .inner_checkout {
    .checkout_content {
      .checkout8 {
        .billing_address_form {
          .form-control {
            border-radius: 0;
          }

          .form-control:focus {
            outline: none;
            box-shadow: none;
          }
          .form-check {
            padding-left: 0.5rem;
          }
        }
        .checkout_main {
          background-color: #fff;
          padding: 15px;
          margin-top: 15px;
        }
        .return_order_main {
          background-color: #fff;
          padding: 15px;
          p,
          li,
          span,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          label,
          div {
            color: #3d3d3d !important;
          }
          p {
            font-size: 14px !important;
          }
          .ant-spin-nested-loading {
            height: calc(100% - 50px);
            .ant-spin-container {
              height: 100%;
            }
          }
        }
        .payment_option {
          .form-control {
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
          }

          .form-control:focus {
            outline: none;
            box-shadow: none;
          }
          background-color: #fff;
          padding: 15px;
          margin-top: 30px;

          .payment_card_option {
            display: flex;
          }
          .payment_card_option img {
            width: 60px;
            height: 30px;
            -o-object-fit: cover;
            object-fit: cover;
          }
          .payment_card_option div {
            margin-right: 16px;
          }
        }
        .checkout_header {
          background-color: #fff;
          color: #383737;
          padding: 15px;
        }
        .checkout_header p {
          margin: 0;
        }
        button.checkout_btn:focus {
          outline: none;
          box-shadow: none;
        }
        button.checkout_btn {
          background-color: #383737;
          color: #fff;
          padding: 8px 50px;
          float: right;
          margin-top: 15px;
        }
        .place_order_button {
          text-align: right;
          button {
            float: none;
          }
        }
      }
      .checkout4 {
        background-color: #fff;
        padding: 15px;
        .summery {
          .title h5 {
            margin: 0;
            padding: 5px 0;
          }
          .summary_content {
            display: flex;
            margin-bottom: 15px;
            justify-content: space-between;
            .img {
              aspect-ratio: 5/7;
            }
            .content {
              width: 100%;
              margin-left: 15px;
            }

            // .content span p {
            //   margin: 5px 0;
            // }
          }
          .summary_count {
            ul.listb li {
              font-weight: 600;
              color: #3d3d3d;
            }
            ul.summary_list {
              display: flex;
              justify-content: space-between;
            }
            ul.summary_list li {
              list-style: none;
            }
          }
          .summary_count ul {
            margin: 0;
            padding: 0;
            list-style: none;
          }
        }
      }
      .payment-method-tab {
        .ant-tabs-nav {
          &::before {
            border-bottom: none;
          }
          // nav list
          .ant-tabs-nav-list {
            width: 100%;
            justify-content: space-between;
            column-gap: 15px;
            .ant-tabs-tab {
              padding: 12px 16px;
              width: 49%;
              border-color: lightgray;
              border-radius: 7px;
              font-size: 16px;
            }
            .ant-tabs-tab-active {
              border: 2px solid #0570de;
              border-radius: 7px;
            }
          }
        }
      }
      .shipping_policy ul {
        margin: 0;
        padding: 0;
        list-style: none;
      }

      .shipping_policy {
        .accordion-body {
          padding: 0 1.25rem 1.2rem;
        }
        margin-top: 15px;
        padding: 15px;
        background-color: #fff;
        ul.policy_list a {
          color: #3d3d3d;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

@media (min-width: 320px) {
  .editable_content label {
    margin-left: 5px;
  }
}

@media (min-width: 370px) and (max-width: 420px) {
  .shipping_editable_area .check_edit[type="radio"] {
    margin-top: 12% !important;
  }
  .shipping_editable_area .edit_shipping img {
    margin-top: 50% !important;
  }
  .editable_content label {
    margin-left: 5px;
  }
}

@media (min-width: 422px) {
  .shipping_editable_area .check_edit[type="radio"] {
    margin-top: 12% !important;
  }
  .shipping_editable_area .edit_shipping img {
    margin-top: 50% !important;
  }
  .editable_content label {
    margin-left: 5px;
  }
}

.select-box-parent ul li {
  color: #3d3d3d;
}

.AfterpayMessage {
  font-family: monospace;
  font-size: 16px !important;
  color: blue;
}

.shipping-method-radio {
  .ant-radio-inner {
    border-color: #3d3d3d;
    &:hover {
      border-color: #3d3d3d;
    }
    &::after {
      background-color: #3d3d3d;
    }
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #3d3d3d;
  }
}


#return-quantity-selector {
  .disabled-icon span {
    color: gray !important;
  }

  .active-icon span {
    color: #3d3d3d !important;
  }
}