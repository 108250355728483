.about-chillfit {
  margin: 30px 0;
  .inner_about_chillfit {
    background-color: #fff;
    padding: 30px;
    .title {
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .about-chillfit .inner_about_chillfit {
    border-radius: 8px;
  }
}
