.welcome_borad_area {
  .d_block {
    display: block;
  }

  .welcome_board_header {
    background-color: #fff;
    margin-bottom: 15px;
    padding: 9px 0;
  }

  .welcome_board_header h5 {
    margin-bottom: -12px;
    margin-top: 10px;
    font-size: 20px;
  }

  .inner_welcome_board {
    margin: 30px 0;

    .welcome_overview {
      .overview_box p {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 15px;
        text-transform: uppercase;
      }

      .overview_box span {
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        font-style: normal;
      }
    }

    .inner_profile_overview {
      .profile_wrapper {
        .form-control {
          border-radius: 0;
        }

        .form-control:focus {
          outline: none;
          box-shadow: none;
        }

        .change_mail_area {
          .form-control {
            border-radius: 0;
          }

          .form-control:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }

    button.flt_right {
      float: right;
    }

    button.btn_profile {
      background-color: #2c2c2c;
      color: #fff;
      padding: 5px 35px;
    }

    button.btn_profile:focus {
      box-shadow: none;
      outline: none;
    }

    button.nav_btn_welcome {
      color: #3d3d3d;
      background: #fff;
      width: 150px;
      border-radius: 10px;
      border: 1px solid #fff;
    }

    button.active {
      border: 1px solid #3d3d3d !important;
    }

    ul.flex_for {
      justify-content: space-between;
    }

    button.overview_btn {
      border: 0;
      background-color: #2c2c2c;
      color: #fff;
      padding: 4px 15px;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      margin-right: 15px;
    }

    button.overview_btn:focus {
      box-shadow: none;
      outline: none;
    }

    .welcome_overview {
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 5px;
      padding: 15px;
    }

    .goto_profile {
      transform: translate(10%, 70%);
    }

    .profile_overview {
      .inner_profile_overview {
        .profile_wrapper {
          background-color: #fff;
          padding: 30px;
          border-radius: 8px;
        }

        .profile_privacy_wrapper {
          background-color: #fff;
          padding: 30px;
          border-radius: 8px;
        }

        .profile_privacy_wrapper h5 span img {
          width: 20px;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }

    .wishlist_area {
      padding: 30px;
      background-color: #fff;
      border-radius: 8px;

      .wishlist_inner {
        .wishlist_product_outer_wrapper {
          .wishlist_product_wrapper {
            display: flex;
            justify-content: space-between;
            margin: auto;
            flex-wrap: wrap;

            .wish_single_product_outer {
              .remove_and_view {
                display: none;
                margin-top: 15px;
                justify-content: space-around;
                .rm_btn {
                  outline: none;
                  box-shadow: none;
                }
                .rm_btn {
                  border: 1px solid #000;
                  border-radius: 25px;
                  padding: 3px 15px;
                  font-size: 14px;
                }
                .view_btn {
                  background-color: #000;
                  color: #fff;
                  border-radius: 25px;
                  padding: 3px 15px;
                  font-size: 14px;
                }
                .view_btn:focus {
                  outline: none;
                  box-shadow: none;
                }
              }
              margin-bottom: 50px;

              .wish_single_product_inner {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin: auto;

                .wish_product_img img {
                  width: 80%;
                  -o-object-fit: cover;
                  object-fit: cover;
                }

                .wish_product_content {
                  margin-left: 17px;
                }

                .wish_product_content h5 {
                  font-size: 16px;
                }

                .wish_product_content p {
                  margin: 8px 0;
                  font-size: 13px;
                }

                .wish_product_content a {
                  border: 1px solid #2b2b2b;
                  color: #3d3d3d;
                  padding: 5px 20px;
                  border-radius: 25px;
                }

                .close_wish_product {
                  position: absolute;
                  margin-left: 110px;
                }

                .close_wish_product img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }
      }
    }

    .orders_product_area {
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;

      .orders_product_outer_wrapper {
        .orders_product_wrapper {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          margin: auto;

          .orders_single_product_outer {
            .orders_single_product_inner {
              hr {
                display: none;
              }
              color: #3d3d3d;
            }
          }
        }

        .orders_product_wrapper > div {
          box-sizing: border-box;
          margin: 50px 50px 0 0;
          width: calc(1 / 4 * 100% - (1 - 1 / 4) * 50px);
          height: auto;
          color: #fff;
        }

        .orders_product_wrapper > div:nth-child(4n) {
          margin-right: 0;
        }

        .orders_product_wrapper::after {
          content: "";
          flex: auto;
        }

        .orders_product_wrapper > div:nth-child(-n + 4) {
          margin-top: 0;
        }

        .orders_product_img img {
          width: 100%;
          -o-object-fit: cover;
          object-fit: cover;
          border-radius: 10px;
          aspect-ratio: 5/7;
        }
      }
    }

    .address_area {
      background-color: #fff;
      padding: 30px 30px 60px 30px;
      border-radius: 8px;

      .inner_address_area {
        button.btn_address {
          background-color: #2c2c2c;
          color: #fff;
          border-radius: 8px;
          padding: 1px 30px;
          margin-top: 8px;
        }

        .addess_overview {
          display: flex;
          justify-content: space-between;

          .address_editbtn {
            color: #3d3d3d;
            text-decoration: underline;
          }

          .close_address {
            margin-left: 22px;
            margin-top: -130px;
            position: absolute;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .welcome_borad_area .inner_welcome_board button.active span {
    margin-top: 8px !important;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  a.wish_view_page {
    display: none;
  }
  .orders_single_product_outer {
    .orders_single_product_inner {
      hr {
        display: block !important;
      }
    }
  }
  .orders_single_product_inner hr {
    display: block;
  }
  .remove_and_view {
    display: flex !important;
  }
  .welcome_borad_area
    .inner_welcome_board
    .wishlist_area
    .wishlist_inner
    .wishlist_product_outer_wrapper
    .wishlist_product_wrapper
    .wish_single_product_outer
    .wish_single_product_inner
    .close_wish_product {
    display: none;
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_top_select
    ul.size_guide
    li,
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_bottom_select
    ul.size_guide
    li {
    padding: 2px 5px;
  }

  .welcome_borad_area .inner_welcome_board button.nav_btn_welcome {
    color: #3d3d3d;
    background: #fff;
    width: 35px;
    border-radius: 66px;
    border: 1px solid #fff;
    height: 35px;
  }

  .welcome_borad_area
    .inner_welcome_board
    .wishlist_area
    .wishlist_inner
    .wishlist_product_outer_wrapper
    .wishlist_product_wrapper
    .wish_single_product_outer
    .wish_single_product_inner
    .wish_product_content
    h5 {
    font-size: 14px;
  }
  .welcome_borad_area
    .inner_welcome_board
    .wishlist_area
    .wishlist_inner
    .wishlist_product_outer_wrapper
    .wishlist_product_wrapper
    .wish_single_product_outer
    .wish_single_product_inner
    .wish_product_img
    img {
    width: 50%;
  }

  .welcome_borad_area
    .inner_welcome_board
    .wishlist_area
    .wishlist_inner
    .wishlist_product_outer_wrapper
    .wishlist_product_wrapper
    .wish_single_product_outer
    .wish_single_product_inner
    .wish_product_content
    p {
    margin: 8px 0;
    font-size: 11px;
    line-height: 14px;
  }
  .orders_product_area {
    .orders_product_outer_wrapper {
      .orders_product_wrapper > div {
        width: calc(1 / 1 * 100% - (1 - 1 / 1) * 50px) !important;
      }

      .orders_product_wrapper > div:nth-child(1n) {
        margin-right: 0 !important;
      }

      .orders_product_wrapper > div:nth-child(-n + 1) {
        margin-top: 0 !important;
      }

      .orders_product_img img {
        width: 100%;
      }
    }
  }

  .nav_btn_welcome_pills .nav-item .nav-link i {
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }

  .order_info_bottom {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 401px) and (max-width: 567px) {
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_top_select
    ul.size_guide
    li,
  .product_details_area
    .product_details_inner
    .gallery_details_box
    .product_details
    .p_bottom_select
    ul.size_guide
    li {
    padding: 2px 10px;
  }

  .nav_btn_welcome_pills .nav-item .nav-link i {
    padding: 5px;
    font-size: 12px;
    margin-top: 5px;
    display: block;
  }
  .order_info_bottom {
    padding-bottom: 15px;
  }

  .welcome_borad_area .inner_welcome_board button.nav_btn_welcome {
    width: 35px;
    height: 35px;
    border-radius: 66px;
  }

  .nav_btn_welcome_pills .nav-item .nav-link i {
    padding: 5px;
    font-size: 12px;
  }

  .orders_product_area {
    .orders_product_outer_wrapper {
      .orders_product_wrapper > div {
        width: calc(1 / 2 * 100% - (1 - 1 / 2) * 50px) !important;
      }

      .orders_product_wrapper > div:nth-child(2n) {
        margin-right: 0 !important;
      }

      .orders_product_wrapper > div:nth-child(-n + 2) {
        margin-top: 0 !important;
      }

      .orders_product_img img {
        width: 100%;
      }
    }
  }
}

@media (min-width: 568px) and (max-width: 991px) {
  .welcome_borad_area .inner_welcome_board button.nav_btn_welcome {
    width: 40px;
    border-radius: 66px;
    height: 40px;
  }
  .nav_btn_welcome_pills .nav-item .nav-link i {
    padding: 5px;
    font-size: 12px;
    margin-top: 8px;
    display: block;
  }
  .orders_product_area {
    .orders_product_outer_wrapper {
      .orders_product_wrapper > div {
        width: calc(1 / 3 * 100% - (1 - 1 / 3) * 50px) !important;
      }

      .orders_product_wrapper > div:nth-child(3n) {
        margin-right: 0 !important;
      }

      .orders_product_wrapper > div:nth-child(-n + 3) {
        margin-top: 0 !important;
      }

      .orders_product_img img {
        width: 100%;
        aspect-ratio: 5/7;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .inner_welcome_board {
    .nav_btn_welcome {
      span {
        display: none;
      }
    }
  }
  .welcome_borad_area .inner_welcome_board button.active {
    width: 92px !important;
    display: inline-flex;
  }
  .welcome_borad_area .inner_welcome_board button.active span {
    display: inline-block;
    margin-top: 5px;
  }

  .welcome_borad_area
    .inner_welcome_board
    .wishlist_area
    .wishlist_inner
    .wishlist_product_outer_wrapper
    .wishlist_product_wrapper
    .wish_single_product_outer
    .wish_single_product_inner
    .close_wish_product {
    position: absolute;
    margin-left: 150px;
  }

  .nav_btn_welcome_pills {
    .nav-item {
      .nav-link {
        padding: 0;
      }
    }
  }
}


.select-box-parent{
  position: relative;
  ul{
    background: #fff;
    list-style: none;
    width: 100%;
    position: absolute;
    padding: 0;
    border: 1px solid grey;
    li{
      margin-bottom: 5px;
      padding: 5px 10px;
      cursor: pointer;
      &:hover{
        background: #8080802b;
      }
    }
  }
}