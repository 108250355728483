.shipping_editable_area {
  margin-bottom: 30px;
  .add_new_btn span {
    cursor: pointer;
  }
  .shipping_editable_inner {
    background-color: #fff;
    padding: 30px;
    .add_new_btn span img {
      width: 16px;
    }
    .add_new_btn span {
      display: table;
      margin: 0 auto;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
    }

    .edit_continue a {
      border-radius: 25px;
      background-color: #3d3d3d;
    }
  }
  .check_edit[type="radio"] {
    margin-top: 22%;
  }
  .edit_shipping img {
    margin-top: 100%;
    cursor: pointer;
  }
}
@media (min-width: 320px) and (max-width: 575.98px) {
  .shipping_editable_inner {
    padding: 15px !important;
  }
  .col_sm_10 {
    flex: 0 0 auto;
    width: 80%;
  }
  .col_sm_2 {
    flex: 0 0 auto;
    width: 20%;
  }
}

@media (min-width: 320px) {
  .fixing_ico img {
    margin-top: 45%;
  }
}
@media (min-width: 375px) {
  .fixing_ico img {
    margin-top: 60%;
  }
}
@media (min-width: 425px) {
  .fixing_ico img {
    margin-top: 35%;
    margin-left: 15px;
  }
}

@media (min-width: 1000px) {
  .shipping_editable_area {
    .check_edit[type="radio"] {
      margin-top: 3% !important;
    }
  }
  .fixing_ico img {
    margin-top: 28%;
    margin-left: 15px;
  }
  .shipping_editable_area {
    .edit_shipping img {
      margin-top: 15% !important;
    }
  }
}
@media (min-width: 1400px) {
  .fixing_ico img {
    margin-top: 11%;
    margin-left: 15px;
  }
}
@media (min-width: 1920px) {
  .fixing_ico img {
    margin-top: 6%;
    margin-left: 15px;
  }
  .shipping_editable_area {
    .check_edit[type="radio"] {
      margin-top: 2% !important;
    }
  }
  .shipping_editable_area {
    .edit_shipping img {
      margin-top: 10% !important;
    }
  }
}

// @media (min-width: 768px) {
//   .shipping_editable_area {
//     display: none;
//   }
// }
