.payment-method-area {
  margin: 30px 0;
  .inner_payment_method {
    background-color: #fff;
    padding: 30px;
    .title {
      margin-bottom: 30px;
    }
  }
}
.payment_option {
  .pay-collapse{
    background-color: transparent;
    .ant-collapse-content > .ant-collapse-content-box{
      padding: 0;
    }
    .ant-radio-inner{
      border-color: #2c2c2c;
    }
    .ant-collapse-item{
      border-bottom: none;
      .ant-collapse-header{
        padding: 2px 0;
      }
   }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #2c2c2c;
    &::after {
      background-color: #2c2c2c;
    }
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #2c2c2c;
  }
}
