.first_ask_q {
  margin: 30px 0;
  .inner_faq {
    .deliver_a {
      .deliver_a_info p {
        margin-bottom: 0;
      }
      // background-color: #fff;
      // padding: 30px;
      .title {
        margin-bottom: 30px;
        h5 {
          text-align: center;
        }
      }
      .info_accordion {
        .accordion-button {
          background-color: #fff;
          color: #3d3d3d;
          padding-left: 0;
          text-decoration: underline;
          font-size: 14px;
        }
        .accordion-button::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }
        .accordion-button:not(.collapsed)::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
          transform: rotate(180deg);
        }
        .accordion-body {
          background-color: #fff;
          color: #3d3d3d;
          padding-left: 0;
        }
        .accordion-button:not(.collapsed) {
          background-color: #f8f8f8;
        }
      }
    }
    .contact_us_a {
      background-color: #fff;
      padding: 30px;
      .title {
        margin-bottom: 30px;
        h5 {
          text-align: center;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .first_ask_q {
    .inner_faq {
      .deliver_a {
        border-radius: 8px;
        // padding: 15px !important;
      }
    }
  }
  .first_ask_q .inner_faq .contact_us_a {
    margin-top: 30px;
    border-radius: 8px;
  }
}
