.customization-area {
  margin: 30px 0;
  .inner_customizer {
    background-color: #fff;
    padding: 30px;
    .title {
      h5 {
        margin-bottom: 30px;
      }
    }
    .content_custom {
      .inner_content {
      }
    }
  }
}
