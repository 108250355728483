span.cancel_search {
  border-radius: 0;
  border-bottom: 1px solid #ededed;
  background: transparent;
  border-right: 0;
  border-top: 0;
  color: #0d96ff;
  cursor: pointer;
}

.mobile_search_area {
  background: #ffffff;
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 999;
  .inner_m_search_area {
    padding: 15px 15px;
    ul {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }
  }
}
