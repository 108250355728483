.join-area {
  margin: 30px 0;
  .inner_join_info {
    background-color: #fff;
    padding: 30px;
    .title {
      h5 {
        margin-bottom: 30px;
      }
    }
  }
}
