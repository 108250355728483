.contact-info {
  margin: 30px 0;
  .contact_info_inner {
    background-color: #fff;
    padding: 30px;
    .contact_data {
      .desc_btm {
        h5 {
          margin-bottom: 30px;
        }
        a {
          text-decoration: underline;
          color: #3d3d3d;
        }
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
  .view_all_faqs {
    height: 50px;
    .allfaqbtn {
      float: right;
      .all_faqs {
        background-color: #3d3d3d;
        color: #fff;
        padding: 5px 35px;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .contact-info {
    .contact_info_inner {
      border-radius: 8px;
    }
  }
}

.contact_us_info{
  p{
    font-size: 14px;
    font-weight: 400;
  }
}