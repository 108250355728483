.banner {
  // margin-top: 30px;
  width: 100%;
  .banner-item-shop {
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    h5 {
      color: #fefcf6;
      font-weight: 700;
    }
    .poster-btn {
      display: inline-block;
      text-transform: uppercase;
      border-radius: 30px;
      padding: 12px 30px;
      background-color: rgba(25, 17, 11, 0.2);
      backdrop-filter: blur(15px);
      height: 100%;
      border: 1px solid white;
      color: white;
    }
  }
  img,
  video {
    width: 100%;
    // aspect-ratio: 21 / 7;
    object-fit: fill;
  }
  .video-loader {
    object-fit: fill;
    // aspect-ratio: 2.696 / 1;
  }
  .video-loader-skeleton{
    height: 80vh;
  }
  .home-carousel-btn {
    top: 49%;
    width: 35px;
    height: 35px;
    img {
      width: 8px;
    }
  }
}
.feature-section {
  margin-top: 42px;
  margin-bottom: 40px;
  .pacifier-title {
    padding: 10px 0px 15px;
  }
  .feature-prod {
    padding: 42px 70px;
  }
  .product-tags {
    position: absolute;
    padding: 8px 0 8px 8px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 990;
    .tag-item {
      background: #fff;
      padding: 3px7px;
      border: 0.5px solid #3d3d3d;
      font-size: 12px;
      font-weight: 300 !important;
      color: #3d3d3d;
      span {
        font-size: 12px;
        font-weight: 300 !important;
        color: #3d3d3d;
      }
    }
  }
}
.sub-title {
  margin-bottom: 18px;
  text-decoration: underline;
}
.pacifier-title h3 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
  // color: red;
}

#shop-collection {
  .shop-collection-inner {
    display: flex;
    align-items: center;
  
    .shop-left-section {
      width: 50%;
    }
   
  }
}

.slider-section {
  .home-slider-arrrow {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #fefcf6 !important;
    border: 1px solid #d7d3ce;
    top: 39%;
    img {
      margin-right: 2px;
      width: 11px;
      aspect-ratio: unset;
    }
  }
  .home-slider-prev {
    left: -20px;
  }
  .home-slider-next {
    right: -20px;
  }
  .slick-list {
    margin: 0 -15px;
  }
  .slick-slide > div {
    padding: 13px 15px;
  }
  .cr-item {
    transition: all 0.3s;
    &:hover {
      transform: scale(1.03);
    }
  }
  // .colors-circle{
  //   position: relative;
  //   width: 16px;
  //   height: 16px;
  //   border-radius: 50%;
  //   box-sizing: border-box;
  //   &::after {
  //     content: '';
  //     position: absolute;
  //     box-sizing: border-box;
  //     border: 1px solid #2d2d2d;
  //     border-radius: 50%;
  //     width: calc(66% + 10px);
  //     height: calc(64% + 10px);
  //     left: -2px;
  //     top: -2px;
  //   }
  // }
}

.home-banner {
  margin-top: 40px;
}
.home-carousel {
  margin-top: 60px;
}

.category-list {
  .box:nth-of-type(even) {
    // margin-bottom: 20px;
  }
  .category-name{
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15px;
    background: #161616;
    color: #ffffff;
    text-align: center;
  }
}
.hover-effect {
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}

.main-image {
  margin-right: 26px;
  img {
    max-width: 977px;
    aspect-ratio: 5/7;
    object-fit: cover;
  }
  h5 {
    font-size: 14px;
  }
  span {
    font-size: 14px;
  }
}
.inner-image-first {
  display: inline-block;
  margin-right: 80px;
  margin-left: 30px;
  margin-bottom: 25px;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
  }
}

.second-row {
  margin-bottom: 0;
}

.inner-image {
  width: 49%;
  display: inline-block;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.03);
  }
  // h5 {
  //   max-width: 120px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }
  // width: 238px;
  img {
    max-width: 390px;
    aspect-ratio: 5/7;
    object-fit: cover;
  }
  h5 {
    font-size: 14px;
    text-align: start;
    margin-top: 10px;
    text-transform: uppercase;
  }
  span {
    font-size: 14px;
    font-family: "neue-kabel";
    font-weight: 400;
  }
}
.product-tags {
  position: absolute;
  padding: 8px 0 8px 8px;
  flex-direction: column;
  justify-content: space-between;
  z-index: 990;
  .tag-item {
    background: #fff;
    padding: 3px 7px;
    border: 0.5px solid #3d3d3d;
    font-weight: 300;
    font-size: 12px;
    span {
      font-size: 12px;
      font-weight: 300 !important;
      color: #3d3d3d;
    }
  }
}

.home-product-content {
  font-size: 14px;
  margin-top: 10px;
  // margin-bottom: 20px;
  h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }
  span {
    font-weight: 400;
  }
}

@media (max-width: 1399.98px) {
  .main-image {
    // margin-right: 25px;
  }
  .inner-image-first {
    display: inline-block;
    margin-right: 45px;
    margin-left: 38px;
    margin-bottom: 55px;
  }
  .second-row {
    margin-bottom: 0;
  }
}

@media (max-width: 1199.98px) {
  .main-image {
    margin-right: 0px;
    img {
      max-width: 977px;
    }
  }
  .inner-image-first {
    display: inline-block;
    margin-right: 47px;
    margin-left: 35px;
    margin-bottom: 15px;
  }
  .second-row {
    margin-bottom: 0;
  }
  // .slick-list{
  //   height: 320px;
  // }
}

@media (max-width: 991.98px) {
  .inner-image-first {
    display: inline-block;
    margin-right: 45px;
    margin-left: 34px;
    margin-bottom: 25px;
  }
}

@media (max-width: 767.98px) {
  .home-carousel {
    margin-top: 30px;
  }
  .feature-prod {
    padding: 0 !important;
    background-color: transparent !important;
  }
  .main-product-content {
    margin-bottom: 50px;
  }
  .first-row-gallery {
    margin-bottom: 20px;
  }
  .main-image {
    margin-right: 0;
    img {
      max-width: 977px;
    }
  }
  .shop-collection-inner {
    padding: 0 24px;
    // align-items: center;
    flex-direction: column-reverse;
    align-items: baseline;
    .shop-left-section {
      width: 100% !important;
    }
    .shop-right-section {
      width: 100% !important;
      margin: 30px 0 10px 0;
      img{
        width: 100%;
      }
    }
   .pacifier-title{
    padding: 10px 0 5px;
   }
  }
  // .home-product-content {
  //   margin-bottom: 20px;
  // }

 .category-list{
  .category-name{
    padding: 7px;
    font-size: 13px;
  }
 }
  .home-category-content {
    margin-bottom: 20px;
  }
  .home-product-content-main {
    margin-bottom: 4px;
  }
  .inner-image-first {
    display: flex;
    margin-right: 0px;
    margin-left: 0;
    margin-bottom: 0;
  }
  .second-row {
    margin-bottom: 0;
  }
  .inner-image {
    display: inline-block;
    // margin-right: 35px;
    width: 45%;
    &:hover {
      transform: unset;
    }
    // img {
    //   max-width: 350px;
    // }
    h5 {
      font-size: 14px;
      text-align: start;
      margin-top: 10px;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    span {
      font-size: 14px;
      font-family: "neue-kabel", sans-serif;
      font-weight: 400;
    }
  }

  section.pacifier-area {
    margin-bottom: 40px !important;
  }
  .slider-section {
    .slick-slide > div {
      padding: 0 15px;
    }
    .cr-item {
      transition: all 0.3s;
      &:hover {
        transform: unset;
      }
    }
  }
  .home-carousel {
    margin-top: 0;
  }
  .feature-section {
    margin-top: 0px;
    margin-bottom: 0px;

    .feature-prod {
      padding: 42px 70px;
    }
    .pacifier-title {
      // margin-top: 16px;
    }
  }
  .pacifier-title {
    padding: 10px 0px 15px;
    .sub-title {
      font-size: 13px;
    }
    h3 {
      margin-bottom: 5px;
      margin-top: 26px;
      font-size: 18px;
      font-weight: 700;
    }
    .for-cat {
      margin-top: 8px;
    }
    .sub-title {
      margin-bottom: 0;
    }
  }
  .hover-effect {
    transition: all 0.3s;
    &:hover {
      transform: unset;
    }
  }

  .category-list {
    .box:nth-of-type(even) {
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 577px) {
  .home-banner{
    margin-top: 20px;
  }
  .banner {
    // margin-top: 15px;
    width: 100%;
    // img,
    video {
      width: 100%;
      // aspect-ratio: 21 / 10;
      height: 70vh;
      object-fit: cover;
      // object-fit: fill;
    }
    .video-loader {
      object-fit: fill;
      // aspect-ratio: 2.696 / 1;
    }
    .home-carousel-btn {
      top: 45%;
      width: 27px;
      height: 27px;
      img {
        width: 6px;
      }
    }
    .banner-item-shop{
      h5{
        font-size: 16px;
      }
      .poster-btn{
        padding: 8px 25px;
        font-size: 10px;
        display: block;
      }
    }
  }
  .slider-section {
    .home-slider-arrrow {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #fefcf6;
      border: 1px solid #d7d3ce;
      top: 34%;
      img {
        width: 8px;
      }
    }
    .home-slider-prev {
      left: -14px;
    }
    .home-slider-next {
      right: -14px;
    }
    .product-tags {
      .tag-item {
        padding: 1px 6px;
        font-size: 10px;
      }
      span {
        font-size: 10px;
      }
    }
  }
  // .feature-section {
  //   margin-top: 15px;
  //   .pacifier-title {
  //     padding: 0px 0px;
  //   }
  //   .feature-prod {
  //     padding: 42px 70px;
  //   }
  // }
  // .sub-title {
  //   margin-bottom: 15px;
  //   text-decoration: underline;
  // }
  // .pacifier-title {
  //   margin: 30px;
  //   h3 {
  //     margin-bottom: 15px;
  //   }
  // }
  .feature-section {
    .product-tags {
      .tag-item {
        padding: 1px 6px;
        font-size: 10px !important;
      }
      span {
        font-size: 10px !important;
      }
    }
  }
}

.inner-drawer {
  .accord-menu-btn {
    font-size: 24px;
    padding: 10px !important;
    color: #3d3d3d;
    font-weight: 600;
    text-transform: uppercase;
    .menu-title {
      font-weight: 700;
      font-size: 14px;
      position: relative;
    }
    .menu-title::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.8px;
      bottom: 0;
      left: 0;
      background-color: #3d3d3d;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
    &:hover {
      .menu-title::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    /* position: relative; */
  }

  // .submenu-body > ul > li {
  //   .list-style {
  //     position: relative;
  //   }
  //   .hover-style {
  //     &::after {
  //       content: "";
  //       position: absolute;
  //       width: 100%;
  //       transform: scaleX(0);
  //       height: 1px;
  //       bottom: 1.5px;
  //       left: 0;
  //       background-color: #000;
  //       transform-origin: bottom right;
  //       transition: transform 0.25s ease-out;
  //     }
  //   }
  //   &:hover {
  //     .hover-style::after {
  //       transform: scaleX(1);
  //       transform-origin: bottom left;
  //     }
  //   }
  // }
}
