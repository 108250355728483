// @import './generic.scss';
// @import './responsive.scss';
@import "./components/product_details";
@import "./components/welcome_board";
@import "./components/order_details";
@import "./components/product_list";
@import "./components/checkout";
@import "./components/order_confirmation";
@import "./components/contact";
@import "./components/customization";
@import "./components/payment";
@import "./components/return_and_exchange";
@import "./components/about";
@import "./components/join";
@import "./components/shipping";
@import "./components/faqs";
@import "./components/shipping_editable";
@import "./components/search_mobile";
@import "./components/home";
@import "./components/announement";
@import "./components/header";
@import "./components/skeleton";
// @import "./slick";
// @import "./slick_theme";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-skeleton-element {
  width: 100%;
  height: 100%;
}
.ant-skeleton-element > .ant-skeleton-avatar {
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
h5,i{
  color: #3d3d3d;
}

.size_guide > li {
  .list-style {
    position: relative;
  }
  .hover-style {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: #3d3d3d;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
  }
  .hover-style-remove {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 7px;
      left: 0;
      background-color: #6a616acc;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
  }
  &:hover {
    .hover-style::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    .hover-style-remove::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.submenu-body > ul > li,
.submenu-header > ul > li {
  .list-style {
    position: relative;
  }
  .hover-style {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 2px;
      left: 0;
      background-color: #3d3d3d;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
  }
  .menu-anime-gap {
    &::after {
      bottom: 0;
    }
  }
  &:hover {
    .hover-style::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.size_guide > li {
  .hover-style {
    &::after {
      bottom: 7px;
    }
  }
}

.ant-empty-image {
  height: 200px;
  img {
    width: 300px;
  }
}
.ant-empty-description {
  font-size: 16px;
  font-weight: 400;
}

.ant-spin-dot-item {
  background-color: #2c2c2c;
}

.stop-scrolling {
  position: fixed;
  overflow: hidden !important;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.highlight-class {
  font-weight: bold;
  background-color: transparent;
  padding: 0;
}

.legal-collapse {
  border: none;
  border-bottom: none;
  .ant-collapse-item {
    border-bottom: none;
    background: #fff;
    .ant-collapse-header{
      align-items: center;
    }
  }
  .ant-collapse-header {
    .ant-collapse-header-text {
      font-size: 14px;
      font-weight: 400;
      .legal-collapse-active{
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(1) !important;
          height: 1px;
          bottom: 3px;
          left: 0;
          background-color: #3d3d3d;
          transform-origin: bottom left;
        }
      }
      .legal-collapse-header {
        display: flex;
        position: relative;
        color: #3d3d3d;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 1px;
          bottom: 3px;
          left: 0;
          background-color: #3d3d3d;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }
      }
    }
    &:hover {
      .ant-collapse-header-text {
        display: flex;
        .legal-collapse-header {
          position: relative;
          color: #3d3d3d;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 3px;
            left: 0;
            background-color: #3d3d3d;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
          &::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }
    }
    .ant-collapse-header-text {
      font-size: 14px;
      font-weight: 400;
      display: flex;
    }
    .ant-collapse-expand-icon {
      .anticon,
      .ant-collapse-arrow {
        font-size: 16px;
        color: #3d3d3d;
      }
    }
  }
  .ant-collapse-content {
    border-top: 0;
    .ant-collapse-content-box {
      padding: 0px 16px 16px 48px;
      p {
        font-size: 14px;
        font-weight: 400;
        color: #3d3d3d;
      }
    }
  }
}

.ant-tooltip {
  z-index: 999999 !important;
  max-width: 270px;
}


@media only screen and (max-width: 768px)  {
  .legal-collapse {
    border: none;
    border-bottom: none;
    .ant-collapse-item {
      border-bottom: none;
      background: #fff;
    }
    .ant-collapse-header {
      
      .ant-collapse-header-text {
        font-size: 14px;
        font-weight: 400;
        .legal-collapse-active{
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(1) !important;
            height: 1px;
            bottom: 3px;
            left: 0;
            background-color: #3d3d3d;
            transform-origin: bottom left;
          }
        }
        .legal-collapse-header {
          display: flex;
          position: relative;
          color: #3d3d3d;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 1px;
            bottom: 3px;
            left: 0;
            background-color: #3d3d3d;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
        }
      }
      &:hover {
        .ant-collapse-header-text {
          display: flex;
          .legal-collapse-header {
            position: relative;
            color: #3d3d3d;
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              transform: scaleX(0);
              height: 0px;
              bottom: 3px;
              left: 0;
              background-color: #3d3d3d;
              transform-origin: bottom right;
              transition: transform 0.25s ease-out;
            }
            &::after {
              transform: scaleX(0);
              transform-origin: bottom left;
            }
          }
        }
      }
      .ant-collapse-header-text {
        font-size: 14px;
        font-weight: 400;
        display: flex;
      }
      .ant-collapse-expand-icon {
        .anticon,
        .ant-collapse-arrow {
          font-size: 16px;
        }
      }
    }
    .ant-collapse-content {
      border-top: 0;
      .ant-collapse-content-box {
        padding: 0px 16px 16px 48px;
        p {
          font-size: 14px;
          font-weight: 400;
          color: #3d3d3d;
        }
      }
    }
  }
}

.paypal-btn-component{
  width: 200px;
  float: right;
  div{
    display: flex;
  }
}

.form-label{
  color: #3d3d3d;
}
.form-control, .cmn-control{
  color: #3d3d3d !important;
}
.search-control{
  color: #ececec !important;
}

// ::-webkit-scrollbar {
//   width: 5px;
//   display: none;
// }

/* Track */
::-webkit-scrollbar-track {
  background: #f4f0eb ; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfcbc7; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f4f0eb; 
}
.mc-banner, .mc-modal {
  top: 25% !important;
}

.menu_carousel{
  .slick-list {margin: 0 -5px;}
  .slick-slide>div {padding: 0 5px;}
}