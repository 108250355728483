.announcement {
  min-height: 45px;
  position: relative;
  overflow: hidden;
  z-index: 1000000;
  background-color: #FDD9E5;
  padding: 10px 0;
  display: flex;
  p {
    font-size: 13px;
    font-weight: 300;
    color: #3d3d3d;
  }

  .moving-announce {
    width: 100%;
    white-space: pre;
    overflow: hidden;
    p {
      display: inline-block;
      padding-left: 100%;
      animation: moving-announce 25s linear infinite;
    }
  }
}

@media (max-width: 788px) {
  .announcement{
    min-height: 0;
    padding: 5px 0;
  }
}

@keyframes moving-announce {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
