.inner-navbar-area {
  position: relative;
}
.custom-dropdown {
  display: none;
  // padding-top: 10px;
  position: absolute;
  top: 54px;
  background-color: #fefcf6;
  border-bottom: 1px solid #cccc;
  &:hover {
    display: block;
  }
  ul {
    margin-top: -4px;
    list-style: none;
    a {
      color: unset;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }
    li {
      background-color: #fefcf6;
    }
  }
}

.navbar-icon {
  height: 54px;
}

.menu-name {
  padding: 0 8px;
  a {
    color: #d6d6d6;
  }
  height: 100%;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #d6d6d6;

  .inner-class {
    position: relative;
    padding: 16px 0;
    color: #FDD9E5;
    &::after {
      transform-origin: 0% 50%;
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 3px;
      transform: scaleX(0);
      transition: transform 250ms ease-in-out;
      top: 50px;
      background: #ffffff;
    }
    &:hover {
      color: #ffffff;
      &::after {
        transform: scaleX(1);
      }
    }
  }
}

.menu-dropdown {
  left: 0;
  border-bottom: 1px solid #cccc;
  .ant-dropdown-menu {
    background-color: #fefcf6;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover,
  .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
  .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
    background-color: #fefcf6;
  }
  ul {
    margin-top: -4px;
    list-style: none;
    a {
      color: unset;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 400;
    }
    li {
      background-color: #fefcf6;
    }
  }
}

.search-container {
  height: 54px;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  section.navbar-area,
  .navbar-icon {
    height: 48px;
  }
}

@media (min-width: 766px) {
  .nav-shopping {
    margin-right: 35px !important;
  }
}

@media (min-width: 767px) and (max-width: 1920px) {
  .item-count span {
    top: 8px;
  }
}
