.return_and_exch {
  margin: 30px 0;
  .inner_return_and_exch {
    background-color: #fff;
    padding: 30px;
    .title {
      h5 {
        margin-bottom: 30px;
      }
    }
  }
}
